import * as React from "react"
import nocturneXImg from "../../assets/images/branding/nocturne-x.svg"
import logoImg from "../../assets/images/graphics/nocturne-logo-only.png"
import {Link} from "gatsby"

const Nav = () => {
    return (
        <nav class="navbar navbar-dark bg-dark fixed-top navbar-expand-lg">
            <div class="container">
                <Link
                    className="navbar-brand"
                    activeClassName="active"
                    to="/"
                >
                    <div className="d-flex align-items-center">
                        <img
                            src={logoImg}
                            className="Img__navbarLogo"
                        />
                        <img
                            src={nocturneXImg}
                            alt="Nocturne X Logo"
                            className="Img__navbar d-none d-lg-block"
                        />
                    </div>
                </Link>

                <div className="d-lg-none">
                            <Link
                                className="Button--nav ms-lg-0 ms-lg-3"
                                to="/#tickets"
                            >
                                Get Tickets
                            </Link>
                        </div>


                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"/>
                </button>

                <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                    <ul className="navbar-nav align-items-center">
                        <li className="nav-item">
                            <Link
                                className="nav-link"
                                activeClassName="active"
                                to="/booking"
                            >
                                BOOKING
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                className="nav-link"
                                activeClassName="active"
                                to="/nightfall"
                            >
                                NIGHTFALL
                            </Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link
                                className="nav-link"
                                activeClassName="active"
                                to="/faq"
                            >
                                FAQ
                            </Link>
                        </li> */}
                        {/* <li className="nav-item">
                            <Link
                                className="nav-link"
                                activeClassName="active"
                                to="/about"
                            >
                                ABOUT
                            </Link>
                        </li> */}
                    </ul>
                </div>

                <div className="d-none d-lg-block">
                            <Link
                                className="Button--nav ms-lg-0 ms-lg-3"
                                to="/#tickets"
                            >
                                Get Tickets
                            </Link>
                        </div>


            </div>
        </nav>
    )
}

export default Nav