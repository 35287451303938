import * as React from "react"
import {Link} from "gatsby"
import epsonLogoImg from "../../assets/images/graphics/epson-logo.png"
import grayAreaImg from "../../assets/images/branding/gray-area.svg"
import Newsletter from "../../components/Newsletter/Newsletter"

const Footer = () => {
    return (
        <div className="Section" id="follow">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col col-12 col-lg-6 order-first">
                        <h2 className="Text--preheading Text--red">
                            Follow our
                        </h2>
                        <h1 className="Text--heading Text--red">
                            Journey
                        </h1>
                        <div className="Social__links">
                            <a
                                href="https://www.facebook.com/nocturnexsf/"
                                rel="noreferrer"
                                target="_blank"
                                className="Button Button--icon Button--red"
                            >
                                <i className="fab fa-facebook"/>
                            </a>
                            <a
                                href="https://www.instagram.com/nocturnexsf/"
                                rel="noreferrer"
                                target="_blank"
                                className="Button Button--icon Button--red"
                            >
                                <i className="fab fa-instagram"/>
                            </a>
                            <a
                                href="https://twitter.com/nocturnexsf"
                                rel="noreferrer"
                                target="_blank"
                                className="Button Button--icon Button--red"
                            >
                                <i className="fab fa-twitter"/>
                            </a>
                        </div>
                    </div>
                    <div className="col col-12 col-lg-6 order-last order-lg-last">
                        <Newsletter/>
                        <p>
                            For media inquiries and press access please <a className="Link--teal"
                                                                        href="https://airtable.com/shr74COYc0hudTE1W"
                                                                        target="_blank" rel="noreferrer">contact
                            us</a>.
                        </p>
                    </div>
                </div>
            </div>

            
            <div className="Container__wrapper">
                <p className="Text--center">
                    Built by <span className="Text--emphasis">
                    <a 
                    className="Link--teal"
                    href="https://numina.studio"
                    target="_blank" rel="noreferrer">
                        Numina Studio
                    </a>
                    </span>
                </p>
                <a
                    href="https://grayarea.org"
                    rel="noreferrer"
                    target="_blank"
                >
                    <img
                        src={grayAreaImg}
                        alt="Gray Area Logo"
                        className="Img__gray-area"
                    />
                </a>

                <p className="Text--center Text--subdued">
                    Thanks to our sponsors:
                </p>
                <a 
                    href="https://epson.com"
                    rel="noreferrer"
                    target="_blank"
                    >
                    <img
                        src={epsonLogoImg}
                        alt="Epson Logo"
                        className="Img__epson"
                    />
                </a>
            </div>



            <p className="Text--center">
                <Link className="Text--subdued Link--light" to="terms">Terms of Service</Link> | <Link
                className="Text--subdued Link--light" to="privacy">Privacy Policy</Link>
            </p>
        </div>

        )
    }
    
export default Footer
