import * as React from "react"
import {
    useEffect,
    useState
} from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp'

const Newsletter = () => {
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [errors, setErrors] = useState(null)

    const onSubmit = (e) => {
        setLoading(true)
        setSuccess(false)
        setErrors(null)
        e.preventDefault()
        addToMailchimp(email).then(data => {
            // I recommend setting data to React state
            // but you can do whatever you want (including ignoring this `then()` altogether)
            setLoading(false)

            if (data?.result === "success") {
                setSuccess(true)
            } else if (data?.result === "error") {
                setSuccess(false)
                let message = data?.msg

                // strip out bad link stuff in message if already subscribed
                message = message.split('to list')[0];

                setErrors(message)
            }
        }).catch((error) => {
          // unnecessary because Mailchimp only ever
          // returns a 200 status code
          // but sometimes the browser will block the call
          
          setSuccess(false)
          setErrors("Looks like your browser is blocking this. Try to disable any tracker-blocking feature and resubmit.")
          setLoading(false)
      })
    }

    useEffect(() => {
        // Clear form out on loads
        setEmail("")
        setLoading(false)
    })

    return (
        <div className="Newsletter">
            <form
                className="row"
                onSubmit={(e) => onSubmit(e)}
            >
                <div className="col-auto">
                    <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="Email"
                        autoComplete="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        disabled={success}
                    />
                </div>
                <div className="col-auto">
                    <button
                        className="btn btn-outline-light"
                        type="submit"
                        disabled={success}
                    >
                        {loading ?
                            <div className="spinner-grow spinner-grow-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            :
                            <span>
                               {success ?
                                   <span>&#10003;</span>
                                   :
                                   <span>Subscribe</span>
                               }
                            </span>
                        }
                    </button>
                </div>
            </form>
            {errors &&
            <div className="Text--subdued Newsletter__error">
                {errors}
            </div>
            }
        </div>
    )
}

export default Newsletter
